import Logan_and_I from "../../images/Logan_and_I.jpg";
import { HashLink } from "react-router-hash-link";

export default function About() {
  return (
    <div className="bg-white overflow-hidden">
            {/* <div className="pt-0 sm:pt-32"/> */}
      <div className="relative max-w-7xl mx-auto pt-12 sm:pb-0 md:pb-0 lg:py-36 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-white absolute top-1 bottom-0 left-3/4 w-screen" />
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <svg
              className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-500"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>
            <div className="relative text-base mx-auto max-w-prose mt-20 lg:mt-0 lg:max-w-none">
              <figure>
                <div className="aspect-w-12 shadow-2xl aspect-h-7 lg:aspect-none">
                  <img
                    className="rounded-lg shadow-2xl object-cover object-center"
                    src={Logan_and_I}
                    alt="My nephew Logan and I"
                    width={1184}
                    height={1376}
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="text-base max-w-prose mx-auto lg:max-w-none pb-10">
              <div>
                <h3 className="mt-2 text-4xl md:text-5xl leading-8 font-extrabold tracking-tight text-gray-900">
                  Let me introduce myself
                </h3>
              </div>
            </div>
            <div className="text-base max-w-prose mx-auto lg:max-w-none">
              <p className="bio text-lg text-gray-500">
                Hello, my name is Christian Van Loon and I'm looking forward to
                being your next{" "}
                <span className="text-gray font-bold">
                  Full Stack Web Developer.
                </span>
              </p>
              <div className="text-lg mt-5 prose prose-indigo text-gray-500 mx-500 lg:max-w-none lg:row-start-1 lg:col-start-1">
                <p className="bio">
                  I am 27 years old, graduated from Bryan University with my
                  certificate and a degree in MERN Full Stack Development with a
                  specialization in both front-end and back-end.
                </p>
              </div>
              <div className="text-lg mt-5 prose prose-indigo text-gray-500 mx-500 lg:max-w-none lg:row-start-1 lg:col-start-1">
                <p className="bio">
                  I have resided in both Southern & Northern California,
                  including the Bay Area. My experience living throughout the state has accustomed me to the
                  different needs of Californians & businesses alike.
                </p>
              </div>
              <div className="text-lg lg:pb-60 mt-5 prose prose-indigo text-gray-500 mx-500 lg:max-w-none lg:row-start-1 lg:col-start-1">
                <p className="bio mb-10 lg:mb-20">
                  If you want to know more visit the{" "}
                  <HashLink
                    path
                    to="/faq#faq_section"
                    className="text-black font-semibold"
                  >
                    Frequently asked questions
                  </HashLink>{" "}
                  section of my page or better yet you can{" "}
                  <HashLink
                    path
                    to="/#contact_section"
                    className="text-black font-semibold"
                  >
                    contact
                  </HashLink>{" "}
                  me directly.
                </p>
              </div>
            </div>
          </div>
          <svg
            className="hidden p-8	 lg:block absolute bottom-8 left-0 -mt-20 -mr-20"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-900"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
