import React from 'react'
// import { Dialog , Transition } from '@headlessui/react'

import emailjs from "emailjs-com";

export default function Contact() {
  // const [isOpen, setIsOpen] = useState(false)

  // function closeModal() {
  //   setIsOpen(false)
  // }
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "cv-designs_template",
        "template_6qqrz39",
        e.target,
        "user_Z1eyMeIYu1X1g3MDYpiUq"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div id="contact_section">
      <div className="relative bg-white">
        <div className="absolute inset-0">
          <div className="absolute inset-y-0 mb-20 left-0 w-1/2 bg-white" />
        </div>
        <div className="relative max-w-7xl lg:mx-32 md:mx-20  lg:grid lg:grid-cols-5">
          <div className="bg-white mt-16 pb-6 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="max-w-lg mx-auto">
              <h2 className="text-2xl font-extrabold tracking-tight text-black sm:text-3xl">
                Get in touch
              </h2>
              <p className="mt-3 text-lg leading-6 text-gray-600">
                Now that you've gotten to know me a little bit, I'd love to hear
                from you and more importantly work with you!
              </p>
            </div>
          </div>
          <div className="py-0 px-4 sm:px-6 lg:col-span-3 lg:mt-24 lg:px-8 xl:pl-12">
            <div className="max-w-lg mx-auto lg:max-w-none">
              <form onSubmit={sendEmail} className="grid grid-cols-1 gap-y-6">
                <div>
                  <label htmlFor="full_name" className="sr-only">
                    Full name
                  </label>
                  <input
                    type="text"
                    name="fullname"
                    id="full_name"
                    autoComplete="name"
                    className="block w-full shadow-md py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    placeholder="Full name"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full shadow-md py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    placeholder="Email"
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="sr-only">
                    Phone
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="tel"
                    className="block w-full shadow-md py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    placeholder="Phone"
                  />
                </div>
                <div>
                  <label htmlFor="message" className="sr-only">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    className="block w-full shadow-md py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    placeholder="Message"
                    defaultValue={""}
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    // onClick={() => setIsOpen(true)}
                    className="transition ease-in-out duration-700 inline-flex mb-16 py-3 px-36 sm:px-32 md:px-56 lg:px-20 border border-transparent shadow-2xl text-base font-medium rounded-md text-white bg-gray-500 hover:bg-black"
                  >
                    Submit
                  </button>
                  {/* <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-16 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-30"  />
            </Transition.Child> */}

            {/* This element is to trick the browser into centering the modal contents. */}
            {/* <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden justify-center align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Submitted!
                </Dialog.Title>
                <div className="mt-4">
                  <button
                    type="button"
                    className="transition ease-in-out duration-700  mb-6 py-3 px-16 sm:px-16 md:px-16 lg:px-16 border border-transparent shadow-2xl text-base font-medium rounded-md text-white bg-gray-500 hover:bg-black "
                    onClick={closeModal}
                  >
                    Got it, thanks!
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
