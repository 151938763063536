import React from "react";

const Parallax = () => {
  return (
    <div className="w-full bg-blue-700">
      <div className="parallax"></div>
    </div>
  );
};

export default Parallax;
