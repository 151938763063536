import Navbar from "../../navigation/Navbar";
import Footer from "../../footer/Footer";
import demo1 from "./demo-images/demo1.jpg";
import demo2 from "./demo-images/demo2.jpg";
import demo3 from "./demo-images/demo3.jpg";
import demo4 from "./demo-images/demo4.jpg";
import demo5 from "./demo-images/demo5.png";
import demo6 from "./demo-images/demo6.png";
import demo7 from "./demo-images/demo7.png";
import demo8 from "./demo-images/demo8.png";


const products = [
  {
    id: 1,
    name: "",
    href: "#",
    imageSrc: `${demo1}`,
    imageAlt: "Joels bikes demo",
  },
  {
    id: 2,
    name: "",
    href: "#",
    imageSrc: `${demo2}`,
    imageAlt: "Recipex",
  },
  {
    id: 3,
    name: "",
    href: "#",
    imageSrc: `${demo3}`,
    imageAlt: "Skrewit skewers",
  },
  {
    id: 4,
    name: "",
    href: "#",
    imageSrc: `${demo4}`,
    imageAlt: "That Bug Guy",
  },
  {
    id: 5,
    name: "",
    href: "#",
    imageSrc: `${demo5}`,
    imageAlt: "The Plaid Pearl Cafe",
  },
  {
    id: 6,
    name: "",
    href: "#",
    imageSrc: `${demo6}`,
    imageAlt: "DigiVote",
  },
  {
    id: 7,
    name: "",
    href: "#",
    imageSrc: `${demo7}`,
    imageAlt: "Weston Realty",
  },
  {
    id: 8,
    name: "",
    href: "#",
    imageSrc: `${demo8}`,
    imageAlt: "Howell & Jacobs",
  },
];

export default function portfolio() {
  return (
    <div id="portfolio_section" className="bg-white">
      <Navbar />
      <div className="pt-32 sm:pt-24 lg:pt-6 lg:px-8"/>
      <div className="max-w-2xl mx-auto px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only  ">Portfolio</h2>
        <div className="grid grid-cols-1  sm:grid-cols-1 gap-x-6 lg:grid-cols-2 xl:grid-cols-2 ">
          {products.map((product) => (
            <p key={product.id} href={product.href} className="group">
              <div className="w-full aspect-w-1 aspect-h-1 bg-white rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-5">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="w-full h-4/6	 object-contain"
                />
              </div>
            </p>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
