import React from "react";
import Navbar from "../../navigation/Navbar";
import About from "./About";
import List from "./List";
import Contact from "./Contact";
import Footer from "../../footer/Footer";
import Parallax from "./Parallax";

const Home = () => {
  return (
    <div id="home_section">
      <Navbar />
      <About />
      <Parallax />
      <List />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
