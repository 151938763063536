import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./components/pages/home-contact/Home";
import Faq from "./components/pages/faq/Faq"
import Contact from "./components/pages/home-contact/Contact"
import Portfolio from "./components/pages/portfolio/Portfolio";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/faq" component={Faq} redirectTo="/" />
          <Route path="/contact" component={Contact} redirectTo="/" />
          <Route path="/portfolio" component={Portfolio} redirectTo="/" />
      </Switch>
    </div>
  );
}

export default App;
