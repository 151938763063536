import { DesktopComputerIcon, DatabaseIcon } from "@heroicons/react/outline";

const actions = [
  {
    title: "FRONT-END",
    description:
      "A front end developer has one general responsibility: to ensure that website vis" +
      "itors can easily interact with the page. They do this through the combination of" +
      " design, technology and programming to code a website's appearance, as well as t" +
      "aking care of debugging.",
    icon: DesktopComputerIcon,
    cards: [
      "html",
      "css",
      "javascript",
      "react.js",
      "api's",
      "git",
      "http",
      "axios",
    ],
  },
  {
    title: "BACK-END",
    description:
      "Backend Development is also known as server-side development. It is everything t" +
      "hat the users don't see and contains behind-the-scenes activities that occur whe" +
      "n performing any action on a website. It focuses primarily on databases, backend" +
      " logic, APIs, and Servers. ... Thus, it is known as the backend",
    icon: DatabaseIcon,
    cards: ["node.js", "python", "express", "mysql", "mongodb", "postgresql"],
  },
];


export default function List() {
  return (
    <div className="lg:mx-32 md:mx-20 rounded-lg text-center overflow-hidden divide-y sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
      {actions.map((type) => (
        <div
          key={type.title}
          className="relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
          <div>
            <span
              className="rounded-l inline-flex p-10 text-4xl ring-4 ring-white">
              <type.icon className="h-9 w-10" aria-hidden="true" />
              <span className="leading-8">{type.title}</span>
            </span>
          </div>
          {type.cards.map((item) => (
            <div key={item.cards} className="box">{item.toUpperCase()}</div>))}
          <div className="mt-8 ">
            <p className="m-7 text-md text-gray-500">{type.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
