import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import Navbar from "../../navigation/Navbar";
import Footer from "../../footer/Footer";

const faqs = [
  {
    title: "What is a MERN Full Stack Developer?",
    entry:
      "MERN is the acronym for MongoDB, Express JS, React JS and Node JS. The MERN stack is a combination of the above technologies, all based on JavaScript, used to build advanced web applications. It is an open source full stack development framework i.e. it provides entire front-end to back-end development components.",
  },
  {
    title: "What is responsive web design? And why is it important?",
    entry:
      "Research found by Google has found that 53% of mobile website visitors will leave if a webpage doesn’t load within three seconds. The average load time for sites is 19 seconds on a 3G connection and 14 seconds on a 4G connection. The report found that mobile sites that loaded within five seconds performed much better than those that took 19 seconds to load: Sites that loaded within 5 seconds boast 25% higher ad viewability, 70% longer average sessions, and a 35% lower bounce rate.",
  },
  {
    title: "Why do I need a website for my business?",
    entry:
      "Studies show that between 70-80% of people research a company online BEFORE visiting a small business or making a purchase with them. 97% of consumers go online to find a local business or local services.",
  },
  {
    title: "Are web developers and a web designers the same thing?",
    entry:
      "A web developer is more concerned with functionality and features of a website, and versed in the programming languages required to create them. Web developers will still need to understand the aesthetics and art direction of the site as they implement features. However, A web designer will create the logos, graphics, and layout that determine the look and feel of a website. Web designers will use software like Adobe Photoshop and Illustrator to create these materials. A web designer with a knowledge of code can help him/her better communicate with the web developer.",
  },

  // More titles...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Faq() {
  return (
    <div id="faq_section" className="bg-white">
      <Navbar />
      <div className="max-w-7xl mx-auto py-6 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center mt-32 text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Frequently asked questions
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.title} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">
                          {faq.title}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform transition duration-700 ease-in-out "
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.entry}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
      <Footer />
    </div>
  );
}
