import React, { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import logo_121 from "../images/logo_121.png";
import "./Navbar.css";

const Navbar = () => {
  const [show, setShow] = useState(true);
  const [click, setClick] = useState(false);

  // TRANSTION NAVBAR
  const controlNavbar = () => {
    if (window.scrollY > 100) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  // HANDLE MOBILE ICON
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <div>
      <nav className={`navbar ${!show ? "navbar-trtn" : " "}`}>
        <HashLink
          to="/#home_section"
          className={`navbar-logo ${!show ? "nav-logo-trtn" : " "}`}
          onClick={closeMobileMenu}
        >
          <img src={logo_121} className="logo" alt="" />
        </HashLink>
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <HashLink
              path
              to="/#home_section"
              className="nav-links text-white font-semibold"
              onClick={closeMobileMenu}
            >
              Home
            </HashLink>
          </li>
          <li className="nav-item font-semibold">
            <HashLink
              to="/portfolio#portfolio_section"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Portfolio
            </HashLink>
          </li>
          <li className="nav-item">
            <HashLink
              to="/#contact_section"
              className="nav-links font-semibold"
              onClick={closeMobileMenu}
            >
              Contact
            </HashLink>
          </li>
          <li className="nav-item">
            <HashLink
              path
              to="/faq#faq_section"
              className="nav-links font-semibold"
              onClick={closeMobileMenu}
            >
              FAQ
            </HashLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
